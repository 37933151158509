<template>
  <integration-component
    ref="formRef"
    :set="setSmtp"
    :get="getSmtp"
    :fields="fields"
    title="Server Settings"
  />
</template>

<script>
import { mapActions } from 'vuex'
import IntegrationComponent from '@/views/settings/general/integrations/view/smtp/IntegrationComponent.vue'
import config from './config'
import mixin from '../mixin'

export default {
  name: 'Smtp',
  components: { IntegrationComponent },
  mixins: [mixin],
  methods: {
    ...mapActions('settingsIntegrations', ['getSmtp', 'setSmtp']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>

<style scoped>

</style>
