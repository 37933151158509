<template>
  <div v-if="!loading">
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <h3 class="text-center font-weight-bolder mb-1">
      {{ $t('SMTP Settings') }}
    </h3>
    <p
      v-if="title"
      class="font-weight-bolder font-medium-5"
    >
      {{ $t(title) }}
    </p>
    <validation-observer
      ref="formVal"
    >
      <b-row>
        <b-col md="6">
          <b-col
            v-for="key in ['smtp_host', 'smtp_port', 'smtp_username', 'smtp_password']"
            :key="key"
            md="13"
          >
            <component
              :is="fields[key].type"
              v-model="item[key]"
              v-bind="getProps(key)"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject) || isEdit"
            />
          </b-col>
        </b-col>
        <b-col md="6">
          <p class="font-weight-bolder">
            {{ $t('SMTP Options') }}
          </p>
          <b-col
            v-for="key in ['smtp_encryption']"
            :key="key"
            md="13"
          >
            <component
              :is="fields[key].type"
              v-model="item[key]"
              v-bind="getProps(key)"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject) || isEdit"
            />
          </b-col>
        </b-col>
      </b-row>

      <p class="font-weight-bolder font-medium-5 mt-1">
        {{ $t('Messaging Defaults') }}
      </p>

      <b-row>
        <b-col md="6">
          <b-col
            v-for="key in ['global_bcc_address', 'admin_email', 'reply_to_email', 'order_department_email','support_department_email', 'send_emails_from']"
            :key="key"
            md="13"
          >
            <component
              :is="fields[key].type"
              v-model="item[key]"
              v-bind="getProps(key)"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject) || isEdit"
            />
          </b-col>
        </b-col>
        <b-col md="6">
          <p class="font-weight-bolder">
            {{ $t('Messaging Options') }}
          </p>
          <b-col
            v-for="key in ['add_email_to_cc', 'read_receipt_email']"
            :key="key"
            md="13"
          >
            <component
              :is="fields[key].type"
              v-model="item[key]"
              v-bind="getProps(key)"
              :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject) || isEdit"
            />
          </b-col>
        </b-col>
      </b-row>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              v-if="!isFormChanged"
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="$router.push({name: 'settings-general-integrations'})"
            >
              {{ $t('Back to List') }}
            </b-button>
            <b-button
              v-else
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="loader"
            >
              <feather-icon
                icon="LCancelIcon"
                size="16"
              />
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div>
            <b-button
              v-if="$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject)"
              variant="success"
              class="saveBtn font-medium-1 font-weight-bolder"
              :disabled="!isFormChanged"
              @click="submit"
            >
              {{ $t('SAVE') }}
            </b-button>
          </div>
        </div>
      </portal>
    </validation-observer>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <skeleton v-else />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import skeleton from '@/views/settings/general/integrations/view/smtp/Skeleton.vue'
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import BSWarningModal from '@core/components/BSWarningModal/BSWarningModal.vue'
import store from '@/store'

export default {
  name: 'IntegrationComponent',
  components: {
    skeleton,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    BSWarningModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fields: {
      type: Object,
      required: true,
    },
    get: {
      type: Function,
      required: true,
    },
    set: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    ...mapState('settingsIntegrations', ['item', 'itemClone']),
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    isFormChanged() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemClone)
    },
  },
  created() {
    this.refetchData()
  },
  methods: {
    ...mapMutations('settingsIntegrations', ['GET', 'GET_CLONE']),
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    refetchData() {
      this.loading = true
      this.GET({})
      this.GET_CLONE({})
      this.get().then(res => {
        const { data } = res.data
        const sendEmailsFrom = this.$store.getters['listModule/getSendEmailsFrom'](data.send_emails_from)
        const updatedData = {
          ...data,
          send_emails_from: sendEmailsFrom,
        }
        this.GET(updatedData)
        this.GET_CLONE(updatedData)
      }).finally(() => {
        this.loading = false
      })
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
        ref: `${fieldName}_ref`,
      }
    },
    loader() {
      this.$refs.formVal.reset()
      const clone = { ...this.itemClone }
      this.GET(clone)
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.$refs.formVal.validate().then(success => {
        if (success) {
          this.onSubmit = true
          const payload = {
            smtp_host: this.item.smtp_host,
            smtp_port: this.item.smtp_port,
            smtp_username: this.item.smtp_username,
            smtp_password: this.item.smtp_password,
            smtp_from_address: this.item.smtp_from_address,
            smtp_from_name: this.item.smtp_from_name,
            smtp_encryption: this.item.smtp_encryption,
            details: {
              send_emails_from: this.item.send_emails_from.id,
              add_email_to_cc: this.item.add_email_to_cc,
              read_receipt_email: this.item.read_receipt_email,
              reply_to_email: this.item.reply_to_email,
              admin_email: this.item.admin_email,
              user_department_email: this.item.user_department_email,
              order_department_email: this.item.order_department_email,
              support_department_email: this.item.support_department_email,
              global_bcc_address: this.item.global_bcc_address,
            },
          }
          this.set(payload)
            .then(() => {
              this.$toast.success(this.$t('Successfully saved'))
              this.refetchData()
            })
            .catch(() => {
              scrollToError(this, this.$refs.formVal)
            }).finally(() => {
              this.onSubmit = false
            })
        }
      })
    },
  },
  setup() {
    const ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION = { action: 'change', subject: 'Integrations' }

    return {
      ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION,
    }
  },
}
</script>
