import {
  CHECKBOX_LEF,
  PASSWORD_INPUT,
  RADIO_GROUP,
  SELECT_INPUT,
  TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    smtp_host: {
      type: TEXT_INPUT,
      label: 'Host',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_port: {
      type: TEXT_INPUT,
      label: 'Port',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_username: {
      type: TEXT_INPUT,
      label: 'Username',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_password: {
      type: PASSWORD_INPUT,
      label: 'Password',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_from_address: {
      type: TEXT_INPUT,
      label: 'Address',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_from_name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    smtp_encryption: {
      type: RADIO_GROUP,
      label: 'Use SSL',
      rules: 'required',
      list: [
        { value: 'SSL', text: 'SSL' },
        { value: 'TLS', text: 'TLS' },
      ],
    },
    // Detail Part
    send_emails_from: {
      type: SELECT_INPUT,
      store: 'sendEmailFromList',
      label: 'Send emails reply-to address',
      rules: '',
      placeholder: 'Select...',
    },

    add_email_to_cc: {
      type: CHECKBOX_LEF,
      placeholder: 'Admin Email to CC...',
      label: 'Add email sender to CC',
      rules: 'required',
    },
    read_receipt_email: {
      type: CHECKBOX_LEF,
      placeholder: 'Read Receipt Email...',
      label: 'Require Read Receipts',
      rules: 'required',
    },
    reply_to_email: {
      type: TEXT_INPUT,
      label: 'User reply-to address',
      rules: '|email',
      placeholder: 'Type...',
    },
    admin_email: {
      type: TEXT_INPUT,
      label: 'Admin Email',
      rules: '|email',
      placeholder: 'Type...',
    },
    user_department_email: {
      type: TEXT_INPUT,
      label: 'User department Email',
      rules: '|email',
      placeholder: 'Type...',
    },
    order_department_email: {
      type: TEXT_INPUT,
      label: 'Orders reply-to address',
      rules: '|email',
      placeholder: 'Type...',
    },
    support_department_email: {
      type: TEXT_INPUT,
      label: 'Customer support reply-to address',
      rules: '|email',
      placeholder: 'Type...',
    },
    global_bcc_address: {
      type: TEXT_INPUT,
      label: 'Global blind carbon copy address',
      rules: '',
      placeholder: 'Type...',
    },

  }
  return {
    fields,
  }
}
