<template>
  <div>
    <h3 class="text-center font-weight-bolder mb-1">
      {{ $t('SMTP Settings') }}
    </h3>
    <p class="font-weight-bolder font-medium-5">{{$t('Server Settings')}}</p>
    <b-row>
      <b-col md="6">
        <div
            v-for="field in ['smtp_host', 'smtp_port', 'smtp_username', 'smtp_password']"
            :key="field"
        >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              height="35px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <span class="inputLabel">{{ 'SMTP Options' }}</span>
        <div
            v-for="field in ['smtp_encryption']"
            :key="field"
            style="height: 30px; display: flex; align-items: center; justify-content: space-between; margin-top: 10px"
        >
          <b-skeleton
              width="100px"
              heigth="20px"
          />
          <b-skeleton
              width="18px"
              height="18px"
              style="border-radius: 1px"
          />
        </div>
      </b-col>
    </b-row>
    <p class="font-weight-bolder font-medium-5">{{$t('Messaging Defaults')}}</p>
    <b-row>
      <b-col md="6">
        <div
            v-for="field in ['global_bcc_address', 'admin_email', 'reply_to_email', 'order_department_email', 'support_department_email', 'send_emails_from']"
            :key="field"
        >
          <span
              class="d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
          <b-skeleton
              height="35px"
              style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <span class="inputLabel">{{ 'Messaging Options' }}</span>
        <div
            v-for="field in ['add_email_to_cc', 'read_receipt_email']"
            :key="field"
            style="height: 30px; display: flex; align-items: center; justify-content: space-between; margin-top: 10px"
        >
          <b-skeleton
              width="18px"
              height="18px"
              style="border-radius: 1px"
          />
          <b-skeleton
              width="200px"
              heigth="20px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {BCol, BRow, BSkeleton, BSkeletonImg} from "bootstrap-vue";
import config from "@/views/settings/general/integrations/view/smtp/config";

export default {
  name: 'skeleton',
  components: {BSkeletonImg, BRow, BCol, BSkeleton},


  setup() {
    const { fields } = config()

    return {
      fields,
    }
  },
};

</script>

<style scoped>

</style>